
<template>
    <div style="margin: 2rem;">
        <v-row>
            <v-col>
                <h2>หลักสูตรของท่าน</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3">
                <v-text-field
                    solo
                    label="search"
                    prepend-inner-icon="mdi-magnify"
                ></v-text-field>
            </v-col>
        </v-row>
        <!-- <template v-for="(course,i) in courseData">
            {{i}}
        </template> -->
        <v-expansion-panels accordion>
            <template v-for="(course,i) in courseData" >
                <!-- {{i}} -->
                <v-expansion-panel  :key="`coures-${i}`">
                <v-expansion-panel-header style="font-weight: bolder;">
                    <v-col class="ml-0">
                        {{course.title}}
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col align="end" style="margin-right:0rem;">

                        <v-menu 
                            offset-y  
                            style="margin-left: -10rem;"
                            anchor="bottom start"
                           
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="black"
                                    v-on="on"
                                    plain
                                    @click.native.stop="onDoSomeThing"
                                >
                                <v-icon >mdi-dots-vertical</v-icon> 
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    :key="1"
                                    @click="handleEditCourseAdmin(course)"
                                >
                                    <v-list-item-title><v-icon>mdi-playlist-edit</v-icon> เเก้ชื่อหลักสูตร </v-list-item-title>
                                </v-list-item>
                                <hr>
                                <v-list-item
                                    :key="2"
                                    @click="handleDelete(course)"
                                >
                                    <v-list-item-title><v-icon>mdi-trash-can-outline</v-icon> ลบผู้ดูเเลทั้งหมด</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                       
                    </v-col>
            
                </v-expansion-panel-header>
                <v-expansion-panel-content style="margin-top: -1.5rem;">
                    <v-row style="margin-top: 0.75rem;" v-for="item in course.adminlist" :key="`${item.email}-${course.title}`">
                        <v-col class="ml-5">
                            <v-row>
                                <h4 style="font-size: smaller;">{{item.nameEN}}</h4>
                            </v-row>
                            <v-row class="mt-3">
                                <h4 style="color: #745CA8; font-size: smaller;">{{item.email}}</h4>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            
            </v-expansion-panel>
            </template>
           
        </v-expansion-panels>
        
        
    </div>
</template>
<script>


export default {
    name: 'CourseManage',
    props: {
        courseData :{
            type : Object
        }
    },
    data: () => ({
        adminList : [
        ] ,
        ModalEdit : false , 
        ModaleRemove : false
    }) , 
    created(){
        this.courseData = JSON.parse(JSON.stringify(this.courseData))
        console.log("course :>", this.courseData)
        
    },
    methods: {
        handleEditCourseAdmin(course){
          this.$emit('modalEdit' ,course)
        },
        handleDelete(course){
            console.log("check remove admin" , course)
        }
    }
}
</script>