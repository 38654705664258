<template>
  <div>
    <v-form @submit.prevent="checkSubmit" :id="`check-create-cmu-exam-form-${courseData._id}`">
    <v-dialog
      v-model="dialog"
      :width="dialogSize"
      scrollable
    >
     <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="#745CA8"
            dark
            v-bind="attrs"
            v-on="on"
        >
            <v-icon>mdi-plus</v-icon>
            Create
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
            <b>ลบผู้ดูแล</b>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false" >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
  
          <!-- <v-row class="py-3">
            <v-col cols="2">
              <h3 style="margin-top: 1.2rem;">E-mail</h3>
            </v-col>

            <v-col>
              
              <v-text-field
                label="CMU Account"
                outlined
                type="text"
            >E-mail</v-text-field>
            </v-col>
          </v-row> -->
          <v-row>
           
            <v-col class="d-flex justify-center" style="flex-wrap: wrap;">
              <template>
                <h3 style="margin-top: 0.25rem; margin-right: 0.5rem;">หลักสูตรที่ดูแล</h3>
                <v-checkbox
                  class="mt-0"
                  v-model="selectAll"
                  label="เลือกทั้งหมด"
                  value="checkAll"
                ></v-checkbox>
                <div class="break"></div>
                </template>
                <v-row class="mt-3">
                  <v-col cols="6"></v-col>
                  <v-col cols="6" style="margin-top: -2rem;"> 
                    <template v-for="(course,i) in courseArr">
                        <v-checkbox
                          class="mt-0"
                          v-model="selected"
                          :label="String(course.title)"
                          :value="course.value"
                          :key="`checkbox-${i}`"
                        ></v-checkbox>
                      <div class="break" :key="`break-${i}`"></div>
                    </template>
                  </v-col>
                </v-row>
               
            </v-col>
          </v-row>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="dark"
            text
            @click="dialog = false"
          >
            ยกเลิก
          </v-btn>
          <v-btn
            color="#69A5FF"
            class="white--text"
            type="submit"
            :form="`check-create-cmu-exam-form-${courseData._id}`"
            :key="`submit-btn-${courseData._id}`"
          >
            บันทึก
          </v-btn>
          
        </v-card-actions> 
        
        
      </v-card>
    </v-dialog>
    
    
    </v-form>
  </div>
</template>

<script>
    export default {
      name: 'RemoveAdminModal',
      props: {
        courseData: {
          type: Object
        },
        openModal: Boolean , 
      },
      computed: {
        selectAll: {
            get: function () {
              // return this.sectionArr ? this.selected.length == this.sectionArr.length : false
              return true
            },
            set: function (value) {
              console.log("click")
              let selected = []

              if (value) {
                this.courseArr.forEach(function (section) {
                  selected.push(section.value)
                })
              }

              this.selected = selected
            }
        }

      },
      data () {
        return {
          dialog: false,
          dialogSize: 500,
          selected: [],
          courseArr: [],
          section: []
        }
      },
      created (){
        // this.createSectionArr()
        
        this.createSectionArr(this.courseData)
      },
      components: {
      },
     
      watch: {
        courseData: function (newVal) {
          console.log('create remove', newVal)
          this.createSectionArr(newVal)
        },
        openModal: function () {
          this.dialog = true
        },
        dialog: function () {
          this.$emit('modalClose', false)
        }
        
      },
      methods: {
       createSectionArr (courseData) {
          console.log("courseData",courseData)
          if(courseData){
            this.courseArr = []
            for(let i=0;i<courseData.length;i++){
              this.courseArr.push({
                title : String(courseData[i].prefixCourseNumber) + ' ' +String(courseData[i].title) , 
                value : courseData[i].prefixCourseNumber
              })
            }
            console.log("select :>" , this.courseArr)
          }
        },
        checkSubmit () {
          if(this.selected.length > 0){
            this.$emit('modalSubmit', this.selected)
            this.selected = []
            this.dialog = false
          }
          
        },
        filterSection (section) {
          let sectionLec = section.substring(0, 3)
          let sectionLab = section.substring(3)
          if (sectionLec === '000' && sectionLab !== '000') {
            return `Lab.${sectionLab}`
          }else if(sectionLec !== '000' && sectionLab === '000'){
            return `Lec.${sectionLec}`
          }
          return 0;
        },
       
      },
    }
</script>