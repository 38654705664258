<template>
    <div style="margin: 2rem;">
        <v-row>
            <v-col>
                <h2>ผู้ดูแลในสังกัด</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3">
                <v-text-field
                    solo
                    label="search"
                    prepend-inner-icon="mdi-magnify"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row style="margin-top: 2rem;" v-for="item in this.adminList" :key="item.name">
            <v-col class="ml-3">
                <v-row>
                    <h3>{{item.nameTH}}</h3>
                </v-row>
                <v-row>
                    <h3 style="color: #745CA8;">{{item.email}}</h3>
                </v-row>
            </v-col>
            <v-spacer></v-spacer>
            <v-col align="end">
                <v-btn
                    @click="onHandleDelete(item)"
                    plain 
                    :key="item.name"
                >
                    <v-icon color="#745CA8">mdi-trash-can-outline</v-icon>    
                </v-btn>
            </v-col>
            
        </v-row>
       
    </div>
</template>
<script>
export default {
    name: 'AdminManage',
    props:{
        handleDeleteAdmin : Function,
        adminList : Array
    },
    data: () => ({
        // adminList : [
        //     {
        //         name : "ปรัชญาภรณ์ ศรียาบ1",
        //         email : "prachayaporn.s@cmu.ac.th"
        //     },
        //     {
        //         name : "ปรัชญาภรณ์ ศรียาบ2",
        //         email : "prachayaporn.s@cmu.ac.th"
        //     },
        //     {
        //         name : "ปรัชญาภรณ์ ศรียาบ3",
        //         email : "prachayaporn.s@cmu.ac.th"
        //     },
        //     {
        //         name : "ปรัชญาภรณ์ ศรียาบ4",
        //         email : "prachayaporn.s@cmu.ac.th"
        //     }
        // ]
    }) , 
    methods: {
        onHandleDelete(account){
            this.$emit('handleDeleteAdmin' , account);
            // console.log("del:>" , account)
        }
    }
    
   
}
</script>