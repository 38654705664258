<template>
  <div>
    <v-form :id="`check-create-course-form`">
    <v-dialog
      v-model="modalCreateCourse"
      :width="680"
    
    >
     <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="#745CA8"
            dark
            v-bind="attrs"
            v-on="on"
        >
            <v-icon>mdi-plus</v-icon>
            Create
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
            <b>เเก้ไขหลักสูตร</b>
            <v-spacer></v-spacer>
            <v-btn icon @click="modalCreateCourse = false" >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
  
          <v-row class="py-3">
            <v-col cols="3">
              <h3 style="margin-top: 1.2rem;">รหัสหลักสูตร</h3>
            </v-col>

            <v-col>
              
              <v-text-field
                label="เลข 3 ตัวหน้า"
                v-model="courseNumber"
                outlined
                type="text"
            ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="py-3">
            <v-col cols="3">
              <h3 style="margin-top: 1.2rem;">ชื่อหลักสูตร</h3>
            </v-col>

            <v-col>
              
              <v-text-field
                label="ชื่อของหลักสูตร"
                v-model="courseName"
                outlined
                type="text"
            ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
          </v-row>
        
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="dark"
            text
            @click.once="modalCreateCourse = false"
          >
            ยกเลิก
          </v-btn>
          <v-btn
            color="#69A5FF"
            class="white--text"
            @click="submitCreateCourse()"
            :form="`check-create-cmu-exam-form-${courseData._id}`"
            :key="`submit-btn-${courseData._id}`"
          >
            บันทึก
          </v-btn>
          
        </v-card-actions> 
        
        
      </v-card>
    </v-dialog>
    
    
    </v-form>
  </div>
</template>

<script>
    export default {
      name: 'EditAdminCourseModal',
      props: {
        courseData: {
          type: Object
        },
        openModal: Boolean
      },
      computed: {
        selectAll: {
            get: function () {
              return true
            },
            set: function (value) {
              let selected = []

              if (value) {
                this.sectionArr.forEach(function (section) {
                  selected.push(section)
                })
              }

              this.selected = selected
            }
        }

      },
      data () {
        return {
          modalCreateCourse: false,
          dialogSize: 500,
          selected: [],
          sectionArr: [],
          section: [] ,
          courseNumber : this.courseData.prefix , 
          courseName : this.courseData.title 

        }
      },
      created (){

      },
      components: {
      },
     
      watch: {
        courseData: function (newVal) {
          if(newVal){
            this.courseNumber = newVal?.prefix ? newVal?.prefix : "" ; 
            this.courseName =  newVal?.title ?  newVal?.title : "" ;
          }
        },
        openModal: function () {
          console.log('create section', this.courseData)
          this.modalCreateCourse = true
        },
        modalCreateCourse: function () {
          this.$emit('modalClose', false)
        }
        
      },
      methods: {
       
        submitCreateCourse () {
          this.modalCreateCourse = false
          this.$emit('submitEdit', this.courseName , this.courseNumber)
        },
      
      },
    }
</script>